$yellow: #ffd60a;
$dark-yellow: #e6c109;
$orange: #ff5b01;
$orangeLight: #ffdecc;
$primary: #000000;
$black: #000000;
$white: #ffffff;
$dark-white: #e6e6e6;
$gray: #808080;
$backgroundGray: #f7f7f7;
$skeletonGray: #c0c0c0;
$error: #dc2626;
$errorDark: #c62222;
$brown: #c38c56;
$purple: #292a41;
$transparentBlack: rgba(0, 0, 0, 0.4);
$cameraBackground: rgba(140, 140, 140, 0.3);
$overlay: rgba(0, 0, 0, 0.8);
$blue: #2449a8;
$selectedGray: #e3e3e3;
$borderGray: #c9c9c9;
$text: #292929;
$inputDescription: #a4a19f;
$layout: #ffffff;
$formGray: #f7f7f7;
$accentGray: #fafafa;
$label: #636363;
$inputBorder: #cbcbcb;
$textLightGray: #969696;
$errorBackground: #fbe9e9;
$black-and-white-10: #f2f2f2;
