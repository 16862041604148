@import '@styles/colors.module.scss';

.button {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  white-space: nowrap;

  background: transparent;

  cursor: pointer;

  width: 100%;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid transparent;

  text-align: center;

  font-size: 16px;
}

.primary {
  background-color: $yellow;

  &:hover {
    background-color: $dark-yellow;
  }
}

.secondary {
  background-color: $white;
  border: 1px solid $borderGray;

  &:hover {
    background-color: $dark-white;
  }
}

.disabled {
  background-color: $borderGray;

  & > p {
    color: $textLightGray;
  }

  div {
    opacity: 0.3;
  }

  &:hover {
    background-color: $borderGray;
  }
}

.transparent {
  background-color: transparent;

  & > p {
    color: $orange;
  }

  &:hover {
    background-color: transparent;
  }
}

.disabledTransparent {
  background-color: transparent;

  & > p {
    color: $textLightGray;
  }

  &:hover {
    background-color: transparent;
  }
}

.icon {
  margin-right: 12px;
  display: flex;
}

.smallIcon {
  margin-right: 8px;
}

.smallButton {
  padding: 8px 16px;
}

.smallText {
  font-size: 12px;
}
