@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.btnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .btn {
    max-width: 95px;
    padding: 0;
    text-align: left;
    justify-content: flex-start;

    & > p {
      color: $orange;
    }
  }
}
