@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.navbar {
  height: 64px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $white;
  padding: 0 16px;

  @include media('tablet') {
    padding: 0 120px;
  }

  a {
    display: flex;
  }

  .btn {
    width: 150px;
    height: 30px;

    font-size: 12px;
  }
}
