@import '@styles/mixins/media';

.layout {
  // height: 100%;
  // min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: url('../../assets/images/mobile-short.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  @include media('mobile') {  
    background: url('../../assets/images/mobile.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  @include media('tablet') {
    background: url('../../assets/images/desktop-vertical.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  @include media('desktop') {
    background: url('../../assets/images/desktop-horizontal.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.center {
  @include media('tablet') {
    margin: auto;
  }
}

.flex {
  flex: 1;
}
