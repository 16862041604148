@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 64px;
  margin: 0 16px;
  padding: 40px 0px;

  @include media('tablet') {
    max-height: 340px;
    flex-direction: row;
    gap: 64px;
    margin: 0 120px;
  }

  .text {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 18px;

    @include media('tablet') {
      flex: 1;
    }

    .primaryText {
      font-size: 32px;
      color: $orange;
    }

    .secondaryText {
      margin-bottom: 22px;
    }
  }

  .imageContainer {
    width: 100%;
    max-width: 600px;
    height: 260px;
    position: relative;
    overflow: hidden;
    background-color: $white;
    border-radius: 16px;

    @include media('tablet') {
      flex: 1;
      height: 340px;
    }

    .image {
      position: absolute;
      width: 600px;
      height: 400px;
      top: 24px;
      left: 40px;

      @include media('tablet') {
        transform: scale(1.4);
        width: 434px;
        height: 248px;
        top: 155px;
        left: 120px;
      }
    }
  }
}
