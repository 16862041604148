@import '../variables';

@mixin media($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
